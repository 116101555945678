import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { useSearchClassesForDashboard } from "../hooks";

export const DanceWorkouts = () => {
  const { classResults: danceWorkouts, loading } = useSearchClassesForDashboard(
    {
      defaultFilters: ["type:'Dance Workouts'"],
    }
  );

  if (danceWorkouts?.length === 0) {
    return null;
  }

  return (
    <Section
      title="Dance Workouts"
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.results__classes}?refinementList[type][0]=Dance%20Workouts`,
        text: "See All",
      }}
    >
      <Slider
        data={danceWorkouts}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.DanceWorkouts}
            selectedFrom="DanceWorkouts"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
